<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
  import "@/assets/site/css/open-iconic-bootstrap.min.css"
  import "@/assets/site/css/style.css"

  export default {
    name: 'App',
  }
</script>