import Vue from 'vue'
import App from './App.vue'
import router from './router/routerSite.js'
import store from './store'

window.$ = window.jQuery = require('jquery');

import 'bootstrap'

//bootstrapvue
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
Vue.use(BootstrapVue);
import 'bootstrap-vue/dist/bootstrap-vue.css'
//end
//Sweet alert
import VueSweetalert2 from 'vue-sweetalert2';
Vue.use(VueSweetalert2);
import 'sweetalert2/dist/sweetalert2.min.css';
//End of sweet alert
import ProductZoomer from 'vue-product-zoomer'
Vue.use(ProductZoomer)

//youtube video player component
import VueYouTubeEmbed from 'vue-youtube-embed';
Vue.use(VueYouTubeEmbed);

import VueGAPI from 'vue-gapi'
const apiConfig = {
    apiKey: 'AIzaSyA1HIvbxqywo1RmZ5521m_zwBi-cJRz7rM',
    clientId: '887917835093-3n8a2u5t1r560ul0p4qkbte1sb6qqp4c.apps.googleusercontent.com',
    discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"],
    scope: 'https://www.googleapis.com/auth/calendar',
}
Vue.use(VueGAPI, apiConfig);

Vue.config.productionTip = false;
router.beforeEach(async(to, from, next) => {
    // Redirect to Dashboard if logged in
    if (localStorage.getItem('userData') !== null) {
        // let userData = JSON.parse(localStorage.getItem('userData'));
        // if (userData.user.role == 'Admin') {
            window.location.href = "/admin";
        // }
    }
    // ##Redirect to Dashboard if logged in
    document.title = process.env.VUE_APP_NAME + ' : ' + to.meta.title;
    next();
});

new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App)
}).$mount('#app')