import Vue from 'vue'
import VueRouter from 'vue-router'

import SiteLayout from '@/views/layout/SiteLayout'
// import VueAxios from 'vue-axios'
// import VueSocialauth from 'vue-social-auth'
// import axios from 'axios';
 
// Vue.use(VueAxios, axios)
// Vue.use(VueSocialauth, {
 
//   providers: {
//     facebook: {
//       clientId: '',
//       redirectUri: '/auth/facebook/callback' // Your client app URL
//     }
//   }
// })
// Site Views ==> Lazy lodading components
const HomeView = () => import('@/views/site/HomeView')


const LoginView = () => import('@/views/site/LoginView')
const SignupView = () => import('@/views/site/SignupView')
const ForgotpasswordView = () => import('@/views/site/ForgotpasswordView')
const Redirect = () => import('@/views/site/HomeView')
//User Views


Vue.use(VueRouter)

var routes;
let userData = JSON.parse(localStorage.getItem('userData'));
var siteRoutesArray = [ //Routes for sites
    { path: '', component: LoginView, name: 'Login', meta: { title: 'Login' } },
   
  
   
 

    { path: '/site', component: LoginView, name: 'Login', meta: { title: 'Login' } },
    { path: '/site/signup', component: SignupView, name: 'Signup', meta: { title: 'Sign up' } },
    { path: '/site/forgot', component: ForgotpasswordView, name: 'Forgot Password', meta: { title: 'Forgot Password' } },
];

routes = [{
    path: '/',
    component: SiteLayout,
    children: siteRoutesArray
},]
// routes = [{
//     path: '/auth/:provider/callback',
//     component: {
//       template: '<div class="auth-component"></div>'
//     }
//   },]

const router = new VueRouter({
    mode: 'history',
    base: process.env.PUBLIC_PATH,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return { selector: to.hash }
        } else {
            return { x: 0, y: 0 };
        }
    }
})

export default router