<template>
  <nav class="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light" id="ftco-navbar">
    <!-- Top Nav -->
    <!-- <div class="navbar fixed-top bg-TopNav d-none d-md-block" v-if="homeview">
      <div class="container">
        <div class="row">
         
         
        </div>
      </div>
    </div> -->
    <!-- Top Nav -->
    <div class="container " :class="{'pt-lg-5': homeview}">
      <a class="navbar-brand" href="#">
        <router-link to="/">
          <img src="@/assets/site/images/logonew1.png"  alt />
        </router-link>
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#ftco-nav"
        aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="oi oi-menu"></span> Menu
      </button>
      <div class="collapse navbar-collapse" id="ftco-nav">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <!-- <a href="#" > -->
            <!-- <router-link to="/" exact data-toggle="collapse" class="nav-link" data-target=".navbar-collapse.show">Home
            </router-link> -->
            <!-- </a> -->
          </li>
          <!-- <li class="nav-item" @click="hideTopbar()"> -->
            <!-- <a href="#" > -->
            <!-- <router-link to="/about" class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show">About
              Us
            </router-link> -->
            <!-- </a> -->
          </li>
       
        
      
<!--     
          <li class="nav-item" @click="hideTopbar()" v-if="!userData">
            <router-link to="/site" class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show">
              Login/SignUp</router-link>
          </li> -->
          <!-- <li class="nav-item dropdown" v-if="userData && userData.user.role=='User'">  
            <router-link to="/user" class="nav-link" id="navbarDropdownMenuLink" data-toggle="collapse"
              aria-haspopup="true" aria-expanded="false" data-target=".navbar-collapse.show">MyAccount</router-link>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <li>
                <router-link to="/user/dashboard" class="dropdown-item no-active" @click="hideTopbar()">
                  My Dashboard
                </router-link>
              </li>
              <li>
                <router-link to="/user/reset_password" class="dropdown-item no-active" @click="hideTopbar()">
                  Reset Password
                </router-link>
              </li>
              <li><router-link to="/user/joned_meetings" class="dropdown-item no-active" @click="hideTopbar()" >Online Classes</router-link></li>
              <li><a class="dropdown-item" href="#" @click="logout">Logout</a></li>
            </ul>
          </li> -->
        </ul>
      </div>
    </div>
  </nav>
</template>


<script>
    export default {
        name: "HomeBar",
        data() {
            return {
                homeview: true,
                userData: [],
            };
        },
        created() {
            this.checkUrl();
            this.setUserDatas();
            if (this.hide) {
                this.hideTopbar();
            }
        },
        methods: {
            hideTopbar() {
                this.homeview = false;
            },
            checkUrl() {
                var segment_str = window.location.pathname; // return segment1/segment2/segment3/segment4
                var segment_array = segment_str.split('/');
                var last_segment = segment_array.pop();
                if (last_segment != '') {
                    this.hideTopbar();
                }
            },
            setUserDatas() {
                var loc_UserData = localStorage.getItem("userData");
                this.userData = JSON.parse(loc_UserData);
            },
            logout() {
                localStorage.clear();
                window.location.href = "/";
            }
        },
      
    };
</script>

<style>
    .router-link-active {
        color: var(--primary) !important;
        border-bottom: 3px solid var(--primary);
        display: inline-block;
    }
    
    .no-active {
        color: var(--dark) !important;
        border-bottom: none !important;
        display: inline-block;
    }
    
    .dropdown-toggle::after {
        display: none;
    }
    /* .dropdown-toggle::after {
        border: none !important;
        font: normal normal normal 14px/1 FontAwesome;
        content: "\f107" !important; */
    /* the desired FontAwesome icon */
    /* vertical-align: 0; */
    /* to center vertically */
    /* } */
</style>